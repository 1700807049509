import {HTTP} from '@/services/http-common'
import * as types from '../mutation-types'
  
// initial state
const state = {
  sponsors: [],
  sponsorCampaigns: []
}

// getters
const getters = {
  sponsors: state => state.sponsors,
  sponsorCampaigns: state => state.sponsorCampaigns
}

// actions
const actions = {
  // SPONSORS
  getSponsors ({ commit }, params) {
    let id = params && params.id ? '/' + params.id : ''
    return HTTP.get('/business_sponsors' + id, {
      params: params
    }).then(resp => {
      if (id === '') {
        commit(types.SET_SPONSORS, resp.data["hydra:member"])
        return resp.data["hydra:member"]
      } else {
        return resp.data
      }
    }, e => {
      console.log(e)
    })
  },
  postSponsor ({ commit }, params) {
    return HTTP.post('/business_sponsors', params).then(resp => {
      commit(types.ADD_SPONSOR, resp.data)
      return resp.data
    }, e => {
      console.log(e)
    })
  },
  deleteSponsor ({ commit }, id) {
    return HTTP.delete('/business_sponsors/' + id)
  },
  putSponsor ({ commit }, payload) {
    return HTTP.put('/business_sponsors/'+payload.id, payload).then(resp => {
      return resp.data
    }, e => {
      console.log(e)
    })
  },
  resetSponsors ({ commit }) {
    commit(types.SET_SPONSORS, [])
  },
  
  // CAMPAIGNS
  getSponsorCampaigns ({ commit }, params) {
    let id = params && params.id ? '/' + params.id : ''
    return HTTP.get('/sponsor_campaigns' + id, {
      params: params
    }).then(resp => {
      if (id === '') {
        commit(types.SET_SPONSORS, resp.data["hydra:member"])
        return resp.data["hydra:member"]
      } else {
        return resp.data
      }
    }, e => {
      console.log(e)
    })
  },
  postSponsorCampaign ({ commit }, params) {
    return HTTP.post('/sponsor_campaigns', params).then(resp => {
      commit(types.ADD_SPONSOR, resp.data)
      return resp.data
    }, e => {
      console.log(e)
    })
  },
  deleteSponsorCampaign ({ commit }, id) {
    return HTTP.delete('/sponsor_campaigns/' + id)
  },
  putSponsorCampaign ({ commit }, payload) {
    return HTTP.put('/sponsor_campaigns/'+payload.id, payload).then(resp => {
      return resp.data
    }, e => {
      console.log(e)
    })
  },
  resetSponsorCampaigns ({ commit }) {
    commit(types.SET_SPONSORS, [])
  }
}

// mutations
const mutations = {
  [types.SET_SPONSORS] (state, payload) {
    state.sponsors = payload
  },
  [types.ADD_SPONSOR] (state, payload) {
    state.sponsors.push(payload)
  },
  [types.SET_SPONSOR_CAMPAIGNS] (state, payload) {
    state.sponsorCampaigns = payload
  },
  [types.ADD_SPONSOR_CAMPAIGN] (state, payload) {
    state.sponsorCampaigns.push(payload)
  }
}

export default {

  state,
  getters,
  actions,
  mutations
}
