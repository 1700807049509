import {HTTP} from '@/services/http-common'
import * as types from '../mutation-types'

const DOT_EXT = '.jsonld'

// initial state
const state = {
  events: [],
  eventRegistrations: [],
  eventStatuses: [],
  eventPricing: [],
  eventStatusOptionsOld: [
    {value: 'pending', text: 'Pending - not listed on website'},
    {value: 'open', text: 'Open - listed on website'},
    // {value: 'active', text: 'Active - currently playing'},
    // {value: 'complete', text: 'Complete - no longer playing'},
    {value: 'archived', text: 'Archived - no longer on website'}
  ],
  eventTypes: [],
  eventLocations: [],
  eventQuestions: [],
  eventGroups: [],
  eventRecords: [],
  eventSchedules: []
}

// getters
const getters = {
    events: state => state.events,
    eventById: state => (id) => {
      return state.events.find(event => event.id === id)
    },
    eventRegistrations: state => state.eventRegistrations,
    eventStatuses: state => state.eventStatuses,
    eventStatusOptions: state => {
      let opt = {}, ret = []
      for (let i in state.eventStatuses) {
        opt = {value: state.eventStatuses[i]['@id'], text: state.eventStatuses[i]['label']}
        ret.push(opt)
      }
      return ret
    },
    eventTypes: state => state.eventTypes,
    eventLocations: state => state.eventLocations,
    eventQuestions: state => state.eventQuestions,
    eventGroups: state => state.eventGroups,
    eventRecords: state => state.eventRecords,
    eventSchedules: state => state.eventSchedules
    
}

// actions
const actions = {
  // events
  getEvents ({ commit }, params) {
    if (!params) {
      params = {}
    }
    return HTTP.get('events' + DOT_EXT, {
      params: params
    }).then(resp => {
      commit(types.SET_EVENTS, resp.data["hydra:member"])
      return resp.data["hydra:member"]
    })
  },
  postEvent ({ commit }, payload) {
    return HTTP.post('events' + DOT_EXT, payload).then(resp => {
      commit(types.ADD_EVENT, resp["hydra:member"])
      return resp.data
    }, error => {
      console.log(error)
    })
  },
  putEvent (_, payload) {
    return HTTP.put('events' + '/' + payload.id + DOT_EXT, payload).then(resp => {
      // @TODO getEvents()
      return resp.data
    })
  },
  getEvent (_, params) {
    if (!params) {
      params = {}
    }
    return HTTP.get('events' + DOT_EXT, {
      params: params
    }).then(resp => {
      return resp.data["hydra:member"][0]
    })
  },
  resetEvents ({ commit }) {
    commit(types.SET_EVENTS, [])
  },
  deleteEvent (_, id) {
    return HTTP.delete('events/' + id)
  },
  // event welcome message
  getEventWelcomeMessage ({ commit }, eventId) {
    if (!eventId) {
      throw new Error('getEventWelcomeMessage requires an event id')
    }
    return HTTP.get('event_welcomes' + DOT_EXT, {
      params: {
        event: eventId
      }
    }).then(resp => {
      commit(types.SET_EVENTS, resp.data["hydra:member"])
      return resp.data["hydra:member"][0]
    })
  },
  postEventWelcomeMessage (_, payload) {
    return HTTP.post('event_welcomes' + DOT_EXT, payload).then(resp => {
      return resp.data
    }, error => {
      console.log(error)
    })
  },
  putEventWelcomeMessage (_, payload) {
    return HTTP.put('event_welcomes' + '/' + payload.id + DOT_EXT, payload).then(resp => {
      return resp.data
    }, error => {
    })
  },
  deleteEventWelcomeMessage (_, id) {
    return HTTP.delete('event_welcomes' + '/' + id)
  },
  // event statuses
  getEventStatuses ({ commit }, params) {
    if (!params) {
      params = {}
    }
    return HTTP.get('event_statuses' + DOT_EXT, {
      params: params
    }).then(resp => {
      commit(types.SET_EVENT_STATUSES, resp.data["hydra:member"])
      return resp.data["hydra:member"]
    })
  },
  postEventStatus ({ commit }, payload) {
    return HTTP.post('event_statuses' + DOT_EXT, payload).then(resp => {
      commit(types.ADD_EVENT_STATUS, resp.data)
      return resp.data
    }, error => {
      console.log(error)
    })
  },
  putEventStatus ({ commit }, payload) {
    return HTTP.put('event_statuses' + '/' + payload.id + DOT_EXT, payload).then(resp => {
      return resp.data
    }, error => {
    })
  },
  deleteEventStatus ({ commit }, id) {
  },
  // event pricing
  postEventPricing ({ commit }, payload) {
    return HTTP.post('event_pricings' + DOT_EXT, payload).then(resp => {
      commit(types.ADD_EVENT_PRICING, resp.data)
      return resp.data
    }, error => {
      console.log(error)
    })
  },
  putEventPricing ({ commit }, payload) {
    return HTTP.put('event_pricings' + '/' + payload.id + DOT_EXT, payload).then(resp => {
      return resp.data
    }, error => {
    })
  },
  deleteEventPricing ({ commit }, id) {
    return HTTP.delete('event_pricings' + '/' + id + DOT_EXT).then(resp => {
      return resp.data
    }, error => {
      console.log(error)
    })
  },
  // event locations
  getEventLocations ({ commit }, params) {
    if (!params) {
      params = {}
    }
    return HTTP.get('event_locations' + DOT_EXT, {
      params: params
    }).then(resp => {
      commit(types.SET_EVENT_LOCATIONS, resp.data["hydra:member"])
      return resp.data["hydra:member"]
    })
  },
  postEventLocation ({ commit }, data) {
    return HTTP.post('event_locations' + DOT_EXT, data).then(resp => {
      commit(types.ADD_EVENT_LOCATION, resp.data)
      return resp.data
    }, error => {
      console.log(error)
    })
  },
  putEventLocation ({ commit }, obj) {
    return HTTP.put('event_locations' + '/' + obj.id + DOT_EXT, obj).then(resp => {
      return resp.data
    }, error => {
    })
  },
  getEventLocation (_ctx, params) {
    if (!params) {
      params = {}
    }
    return HTTP.get('event_locations' + DOT_EXT, {
      params: params
    }).then(resp => {
      return resp.data["hydra:member"][0]
    })
  },
  deleteEventLocation () {
  },
  resetEventLocation ({ commit }) {
    commit(types.SET_EVENT_LOCATIONS, [])
  },
  // event types
  getEventTypes ({ commit }, params) {
    if (!params) {
      params = {}
    }
    return HTTP.get('event_types' + DOT_EXT, {
      params: params
    }).then(resp => {
      commit(types.SET_EVENT_TYPES, resp.data["hydra:member"])
      return resp.data["hydra:member"]
    })
  },
  postEventType ({ commit }, data) {
    return HTTP.post('event_types' + DOT_EXT, data).then(resp => {
      commit(types.ADD_EVENT_TYPE, resp.data)
      return resp.data
    }, error => {
      console.log(error)
    })
  },
  putEventType (_ctx, obj) {
    return HTTP.put('event_types' + '/' + obj.id + DOT_EXT, obj).then(resp => {
      return resp.data
    })
  },
  deleteEventType () {
  },
  // event questions
  getEventQuestions ({ commit }, params) {
    if (!params) {
      params = {}
    }
    return HTTP.get('registration_questions' + DOT_EXT, {
      params: params
    }).then(resp => {
      commit(types.SET_EVENT_QUESTIONS, resp.data["hydra:member"])
      return resp.data["hydra:member"]
    })
  },
  postEventQuestion ({ commit }, data) {
    return HTTP.post('registration_questions' + DOT_EXT, data).then(resp => {
      commit(types.ADD_EVENT_QUESTION, resp.data)
      return resp.data
    }, error => {
      console.log(error)
    })
  },
  putEventQuestion (_ctx, obj) {
    return HTTP.put('registration_questions' + '/' + obj.id + DOT_EXT, obj).then(resp => {
      return resp.data
    }, error => {
      console.log(error)
    })
  },
  // event_registrations
  putEventRegistration (_ctx, obj) {
    return HTTP.put('event_registrations' + '/' + obj.id, obj).then(resp => {
      let regObj = state.eventRegistrations.find(reg => reg.id === obj.id)
      // console.log(regObj)
      return resp.data
    }, error => {
      console.log(error)
    })
  },
  postEventRegistration ({ commit }, data) {
    return HTTP.post('event_registrations', data).then(resp => {
      commit(types.ADD_EVENT_REGISTRATION, resp.data)
      return resp.data
    }, error => {
      console.log(error)
    })
  },
  getEventRegistrations ({ commit }, params) {
    return HTTP.get('event_registrations' + DOT_EXT, {
      params: params
    }).then(resp => {
      commit(types.SET_EVENT_REGISTRATIONS, resp.data["hydra:member"])
      return resp.data["hydra:member"]
    })
  },
  deleteEventRegistration (_ctx, id) {
    return HTTP.delete('event_registrations/' + id)
  },
  resetEventRegistrations ({ commit }) {
    commit(types.SET_EVENT_REGISTRATIONS, [])
  },
  getEventRegistration (_ctx, rid) {
    return HTTP.get('event_registrations/' + rid).then(resp => {
      return resp.data
    })
  },
  // event_groups
  getEventGroups ({ commit, state }, params) {
    state.eventGroups = []
    return HTTP.get('event_groups' + DOT_EXT, {
      params: params
    }).then(resp => {
      commit(types.SET_EVENT_GROUPS, resp.data["hydra:member"])
      return resp.data["hydra:member"]
    })
  },
  postEventGroup ({ commit }, data) {
    return HTTP.post('event_groups', data).then(resp => {
      commit(types.ADD_EVENT_GROUP, resp.data)
      return resp.data
    }, error => {
      console.log(error)
    })
  },
  putEventGroup (_ctx, obj) {
    return HTTP.put('event_groups' + '/' + obj.id, obj).then(resp => {
      let groupObj = state.eventGroups.find(group => group.id === obj.id)
      console.log(groupObj)
      return resp.data
    }, error => {
      console.log(error)
    })
  },
  deleteEventGroup (_ctx, id) {
    return HTTP.delete(`event_groups/${id}`)
  },
  // event_record
  getEventRecords ({ commit, state }, params) {
    state.eventRecords = []
    return HTTP.get('event_records' + DOT_EXT, {
      params: params
    }).then(resp => {
      commit(types.SET_EVENT_RECORDS, resp.data["hydra:member"])
      return resp.data["hydra:member"]
    })
  },
  postEventRecord ({ commit }, data) {
    return HTTP.post('event_records', data).then(resp => {
      commit(types.ADD_EVENT_RECORD, resp.data)
      return resp.data
    }, error => {
      console.log(error)
    })
  },
  putEventRecord (_ctx, obj) {
    return HTTP.put('event_records' + '/' + obj.id, obj).then(resp => {
      let groupObj = state.eventRecords.find(group => group.id === obj.id)
      console.log(groupObj)
      return resp.data
    }, error => {
      console.log(error)
    })
  },
  // event_schedules
  getEventSchedules ({ commit }, params) {
    return HTTP.get('event_schedules' + DOT_EXT, { params }).then(resp => {
      commit(types.SET_EVENT_SCHEDULES, resp.data["hydra:member"])
      return resp.data["hydra:member"]
    })
  },
  postEventSchedule ({ commit }, data) {
    return HTTP.post('event_schedules', data).then(resp => {
      commit(types.ADD_EVENT_SCHEDULE, resp.data)
      return resp.data
    }, error => {
      console.log(error)
    })
  },
  putEventSchedule (_, data) {
    return HTTP.put('event_schedules' + '/' + data.id, data).then(resp => {
      return resp.data
    }, error => {
      console.log(error)
    })
  }
}

// mutations
const mutations = {
  [types.SET_EVENTS] (state, payload) {
    state.events = payload
  },
  [types.ADD_EVENT] (state, payload) {
    state.events.push(payload)
  },
  [types.SET_EVENT_QUESTIONS] (state, payload) {
    state.eventQuestions = payload
  },
  [types.ADD_EVENT_QUESTION] (state, payload) {
    state.eventQuestions.push(payload)
  },
  [types.SET_EVENT_STATUSES] (state, payload) {
    state.eventStatuses = payload
  },
  [types.ADD_EVENT_STATUS] (state, payload) {
    state.eventStatuses.push(payload)
  },
  [types.SET_EVENT_PRICING] (state, payload) {
    state.eventPricing = payload
  },
  [types.ADD_EVENT_PRICING] (state, payload) {
    state.eventPricing.push(payload)
  },
  [types.SET_EVENT_LOCATIONS] (state, payload) {
    state.eventLocations = payload
  },
  [types.ADD_EVENT_LOCATION] (state, payload) {
    state.eventLocations.push(payload)
  },
  [types.SET_EVENT_TYPES] (state, payload) {
    state.eventTypes = payload
  },
  [types.ADD_EVENT_TYPE] (state, payload) {
    state.eventTypes.push(payload)
  },
  [types.SET_EVENT_REGISTRATIONS] (state, payload) {
    state.eventRegistrations = payload
  },
  [types.ADD_EVENT_REGISTRATION] (state, payload) {
    state.eventRegistrations.push(payload)
  },
  [types.SET_EVENT_GROUPS] (state, payload) {
    state.eventGroups = payload
  },
  [types.ADD_EVENT_GROUP] (state, payload) {
    state.eventGroups.push(payload)
  },
  [types.SET_EVENT_RECORDS] (state, payload) {
    state.eventRecords = payload
  },
  [types.ADD_EVENT_RECORD] (state, payload) {
    state.eventRecords.push(payload)
  },
  [types.SET_EVENT_SCHEDULES] (state, payload) {
    state.eventSchedules = payload
  },
  [types.ADD_EVENT_SCHEDULE] (state, payload) {
    state.eventSchedules.push(payload)
  }
}

export default {

  state,
  getters,
  actions,
  mutations
}
