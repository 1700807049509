import {HTTP} from '@/services/http-common'
import * as types from '../mutation-types'

const DOT_EXT = '.jsonld'
  
// initial state
const state = {
  participants: [],
  participantRalationships: [],
  userRalationships: []
}

// getters
const getters = {
  participants: state => state.participants,
  participantRalationships: state => state.participantRalationships,
  userRalationships: state => state.userRalationships
}

// actions
const actions = {
  // participants
  getParticipants ({ commit }, params) {
    return HTTP.get('/participants' + DOT_EXT, {
      params: params
    }).then(resp => {
      commit(types.SET_PARTICIPANTS, resp.data["hydra:member"])
    }, e => {
      console.log(e)
    })
  },
  getParticipant ({ commit }, params) {
    return HTTP.get('/participants' + DOT_EXT, {
      params: params
    }).then(resp => {
      return resp.data["hydra:member"][0]
    }, e => {
      console.log(e)
    })
  },
  resetParticipants ({ commit }) {
    commit(types.SET_PARTICIPANTS, [])
  },
  postParticipant ({ commit }, params) {
    return HTTP.post('/participants' + DOT_EXT, params).then(resp => {
      commit(types.ADD_PARTICIPANT, resp.data)
      return resp.data
    }, e => {
      console.log(e)
    })
  },
  putParticipant ({ commit }, payload) {
    return HTTP.put('/participants/'+payload.id + DOT_EXT, payload).then(resp => {
      return resp.data
    }, e => {
      console.log(e)
    })
  },

  // participant relationships
  getParticipantRelationships ({ commit }, params) {
    return HTTP.get('/participant_relationships' + DOT_EXT, {
      params: params
    }).then(resp => {
      commit(types.SET_PARTICIPANT_RELATIONSHIPS, resp.data["hydra:member"])
      return resp.data["hydra:member"]
    }, e => {
      console.log(e)
    })
  },
  getParticipantRelationship ({ commit }, params) {
    return HTTP.get('/participant_relationships' + DOT_EXT, {
      params: params
    }).then(resp => {
      return resp.data["hydra:member"][0]
    }, e => {
      console.log(e)
    })
  },
  resetParticipantRelationships ({ commit }) {
    commit(types.SET_PARTICIPANT_RELATIONSHIPS, [])
  },
  postParticipantRelationship ({ commit }, payload) {
    return HTTP.post('/participant_relationships' + DOT_EXT, payload).then(resp => {
      commit(types.ADD_PARTICIPANT_RELATIONSHIP, resp.data)
      return resp.data
    }, e => {
      console.log(e)
    })
  },
  putParticipantRelationship ({ commit }, payload) {
    return HTTP.put('/participant_relationships/'+payload.id + DOT_EXT, payload).then(resp => {
      return resp.data
    }, e => {
      console.log(e)
    })
  },
  deleteParticipantRelationship ({ commit }, id) {
    return HTTP.delete('/participant_relationships/' + id + DOT_EXT).then(resp => {
      //
    }, e => {
      console.log(e)
    })
  },

  // user relationships
  getUserRelationships ({ commit }, params) {
    return HTTP.get('/user_relationships' + DOT_EXT, {
      params: params
    }).then(resp => {
      commit(types.SET_USER_RELATIONSHIPS, resp.data["hydra:member"])
    }, e => {
      console.log(e)
    })
  },
  getUserRelationship ({ commit }, params) {
    return HTTP.get('/user_relationships' + DOT_EXT, {
      params: params
    }).then(resp => {
      return resp.data["hydra:member"][0]
    }, e => {
      console.log(e)
    })
  },
  resetUserRelationships ({ commit }) {
    commit(types.SET_USER_RELATIONSHIPS, [])
  },
  postUserRelationship ({ commit }, params) {
    return HTTP.post('/user_relationships' + DOT_EXT, params).then(resp => {
      commit(types.ADD_USER_RELATIONSHIP, resp.data)
      return resp
    }, e => {
      console.log(e)
    })
  },
  putUserRelationship ({ commit }, payload) {
    return HTTP.put('/user_relationships/'+payload.uid + DOT_EXT, payload).then(resp => {
      return resp
    }, e => {
      console.log(e)
    })
  },
  deleteUserRelationship ({ commit }, id) {
    return HTTP.delete('/user_relationships/' + id + DOT_EXT).then(resp => {
      //
    }, e => {
      console.log(e)
    })
  }
}

// mutations
const mutations = {
  [types.SET_PARTICIPANTS] (state, payload) {
    state.participants = payload
  },
  [types.ADD_PARTICIPANT] (state, payload) {
    state.participants.push(payload)
  },
  [types.SET_PARTICIPANT_RELATIONSHIPS] (state, payload) {
    state.participantRalationships = payload
  },
  [types.ADD_PARTICIPANT_RELATIONSHIP] (state, payload) {
    state.participantRalationships.push(payload)
  },
  [types.SET_USER_RELATIONSHIPS] (state, payload) {
    state.userRalationships = payload
  },
  [types.ADD_USER_RELATIONSHIP] (state, payload) {
    state.usaerRalationships.push(payload)
  }
}

export default {

  state,
  getters,
  actions,
  mutations
}
