<template>
  <router-view></router-view>
</template>

<script>
// add some global data
import Vue from 'vue'
import miniToastr from 'mini-toastr'
import VueNotifications from 'vue-notifications'
import BtnPrint from './components/BtnPrint'
import VueProgressBar from 'vue-progressbar'
import Geocoder from '@pderas/vue2-geocoder'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import JsonExcel from '@/components/vue-json-excel'
import VCalendar from 'v-calendar'
import Vuelidate from 'vuelidate'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { constants } from '@/shared/constants'
import * as VueGoogleMaps from "vue2-google-maps"
import './shared/filters'
import './registerServiceWorker'
Vue.use(CKEditor)
Vue.use(VCalendar)
Vue.use(Vuelidate)
Vue.use(VueReCaptcha, {
  siteKey: '6Lev0MQUAAAAAGXcjWjIJrxnSon8YU0hjb3JQs4X',
  loaderOptions: {
    autoHideBadge: true
  }
})
const VueProgressBarOptions = {
  color: '#bffaf3',
  failedColor: '#874b4b',
  thickness: '5px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
}
Vue.use(VueProgressBar, VueProgressBarOptions)
Vue.use(Geocoder, {
    defaultCountryCode: 'US',
    defaultLanguage:    'en',
    defaultMode:        'address',
    googleMapsApiKey:   'AIzaSyA56p9lm7MatuhZnJqDpauUn0ZWskDBYkU'
})
Vue.use(require('vue-moment'))
Vue.component('downloadExcel', JsonExcel)
Vue.component('BtnPrint', BtnPrint)
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDI2MQsAqjCtNdHbLaV6dZLAVnydgmFYVU",
    libraries: "places"
  }
})
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
}
miniToastr.init({types: toastTypes})
function toast ({title, message, type, timeout, cb}) {
  return miniToastr[type](message, title, timeout, cb)
}
// prototypes
Vue.prototype.$log = console.log.bind(console)
// toast
const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
}
Vue.use(VueNotifications, options)
const ROLE_ADMIN = 'ROLE_ADMIN'
const ROLE_DIRECTOR = 'ROLE_DIRECTOR'
const ROLE_COACH = 'ROLE_COACH'
const ROLE_COACH_JR = 'ROLE_COACH_JR'
Vue.mixin({
  computed: {
    storeStates () {
      return this.$store.state
    },
    currentBusiness () {
      return this.$store.getters.business
    },
    autoMultiPlayerDiscount () {
      return this.$store.getters.business.autoMultiPlayerDiscount || 1
    },
    sitePublicDomain () {
      return constants.SITE_PUBLIC_DOMAIN
    },
    currentBusinessCoverage () {
      if (!this.$store.getters.business.coverage) {
        return ''
      }
      return this.$store.getters.business.coverage.map((item) => {
        return item['postalCode']
      }).join(', ')
    },
    currentUser () {
      return this.$store.getters.currentUser
    },
    currentUserRole () {
      return this.currentUser.roles[0]
    },
    currentUserRoleText () {
      let str = this.currentUser.roles[0].substr(5).toLowerCase()
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    isAdmin () {
      return (this.currentUserRole === ROLE_ADMIN)
    },
    isDirector () {
      return (this.currentUserRole === ROLE_DIRECTOR)
    },
    isCoach () {
      return (this.currentUserRole === ROLE_COACH)
    },
    isCoachJr () {
      return (this.currentUserRole === ROLE_COACH_JR)
    },
    userRoleOptions () {
      let roles = [
        { text: 'MEMBER', value: 'ROLE_MEMBER' },
        { text: 'COACH', value: 'ROLE_COACH' },
        { text: 'COACH (Jr.)', value: 'ROLE_COACH_JR' }
      ]
      if (this.isAdmin) {
        roles.push({ text: 'ADMIN', value: 'ROLE_ADMIN' })
        roles.push({ text: 'ACCOUNTING', value: 'ROLE_ACCOUNTING' })
        roles.push({ text: 'DIRECTOR', value: 'ROLE_DIRECTOR' })
      }
      return roles
    }
  },
  methods: {
    toSlug (str) {
      if (!str) return null
      return str.toLowerCase().
        replace(/\s/g , "-").
        replace(/[^a-z0-9_-]/g , "")
    },
    objPropsToStr(arr, prop){
      return arr.map((item) => {
        return item[prop]
      }).join(', ')
    },
    eventArchive (id) {
      return this.$store.dispatch('putEvent', {
        id: id,
        status: '/api/event_statuses/5'
      })
    },
    // @TODO: move to a separate common library
    async recalculateInvoice (id) {
      let amount = 0 // total amount
      let amountTaxable = 0 // total amount taxable
      let amountNonTaxable = 0 // total amount non-taxable
      let discount = 0
      let discountAmount = 0 // discount amount
      let tax = 0 // tax amount
      let amountDue = 0 // total amount after discount and tax
      let serviceTaxAmount = parseFloat(this.currentBusiness.serviceTaxAmount)
      let transactionId = null
      let multiChildDiscountAmount = 10 // 10%
      let multiChildDiscountItem = null
      let mCDiscount = 0
      let mCDiscountAmount = 0
      let regexMultichild = new RegExp("^MULTICHILD", "g")

      try {
        let inv = await this.$store.dispatch('getInvoice', { id: id })
        // calculate amount due
        for (let i = 0; i < inv.items.length; i++) {
          let item = inv.items[i]
          if (regexMultichild.test(item.pid)) {
            // multi-child discount added to invoice
            multiChildDiscountItem = item.id
          } else {
            amount += item.price
            if (item.taxable) {
              amountTaxable += item.price 
            } else {
              amountNonTaxable += item.price
            }
          }
        }
        // set discountAmount
        if (inv.discountCode) {
          if (inv.discountCode.type === 'percent') {
            discount = (inv.discountCode.amount / 100)
            discountAmount = +((amountTaxable * discount).toFixed(2))
          } else {
            discountAmount = inv.discountCode.amount
          }
        }
        // compare discount amount to multi-child discount
        if (multiChildDiscountItem) {
          mCDiscount += (multiChildDiscountAmount / 100)
          mCDiscountAmount = +((amountTaxable * mCDiscount).toFixed(2))
          if (mCDiscountAmount > discountAmount) {
            // use multi-child discount 
            discountAmount = mCDiscountAmount
            await this.$store.dispatch('putInvoiceItem', {
              id: multiChildDiscountItem,
              price: mCDiscountAmount
            })
            if (inv.discountCode) {
              // remove discount code
              await this.$store.dispatch('deleteDiscountCode', inv.discountCode.id)
            }
          } else {
            // remove multi-child discount
            await this.$store.dispatch('deleteInvoiceItem', {
              iid: id,
              iiid: multiChildDiscountItem
            
            })
          }
        }
        // apply discount
        if (amountTaxable > discountAmount) {
          amountTaxable -= discountAmount
        } else {
          amountTaxable = 0
        }
        // add tax
        if (serviceTaxAmount) {
          tax = +((amountTaxable * serviceTaxAmount).toFixed(2))
          amountTaxable += tax
        }
        // add non-taxable amount / non-discounted amount
        amountDue = amountTaxable + amountNonTaxable
        // check for free invoice
        if (amountDue < 0.01) {
          transactionId = 'FREE'
        }
        // update invoice
        return await this.$store.dispatch('putInvoice', { id, amount, amountDue, tax, transactionId })
      } catch (err) {
        console.error(err)
      }
    },

    //////////////////////

    async XrecalculateInvoice (id) {
      let amount = 0 // total amount
      let discount = 0 // discount amount
      let discountAmount = 0 // discount amount
      let tax = 0 // tax amount
      let amountDue = 0 // total amount after discount and tax
      let transactionId = null
      let serviceTaxAmount = parseFloat(this.currentBusiness.serviceTaxAmount)
      let multiChildDiscountAmount = 10 // 10%
      let multiChildDiscountItem = null
      let mCDiscount = 0
      let mCDiscountAmount = 0
      let regexMultichild = new RegExp("MULTICHILD", "g")

      try {
        let inv = await this.$store.dispatch('getInvoice', { id: id })
        // check if invoice has been paid
        if (inv.transactionId && inv.transactionId !== constants.TID_FREE) {
          return inv
        } else if (inv.transactionId && inv.transactionId === constants.TID_FREE) {
          // remove transaction id
          await this.$store.dispatch('putInvoice', {
            id,
            transactionId: null
          })
        }
        // calculate amount (taxable items only)
        for (let i = 0; i < inv.items.length; i++) {
          let item = inv.items[i]
          if (regexMultichild.test(item.pid)) {
            // multi-child discount added to invoice
            multiChildDiscountItem = item.id
          } else if (item.taxable) {
            // console.log('taxable item', item)
            amount += item.price
            amountDue += item.price
          }
        }
        // subtract discount before adding tax
        if (inv.discountCode) {
          console.log('discounted amount', amountDue)
          if (inv.discountCode.type === 'percent') {
            discount = (inv.discountCode.amount / 100)
            discountAmount -= +((amountDue * discount).toFixed(2))
          } else {
            discountAmount -= inv.discountCode.amount
          }
        }
        // compare discount amount to multi-child discount
        if (multiChildDiscountItem) {
          mCDiscount += (multiChildDiscountAmount / 100)
          mCDiscountAmount = +((amountDue * mCDiscount).toFixed(2))
          if (mCDiscountAmount > discountAmount) {
            // use multi-child discount 
            discountAmount = mCDiscountAmount
            await this.$store.dispatch('business/putInvoiceItem', {
              id: multiChildDiscountItem,
              price: mCDiscountAmount
            })
            if (inv.discountCode) {
              // remove discount code
              await this.$store.dispatch('business/deleteDiscountCode', inv.discountCode.id)
            }
          } else {
            // remove multi-child discount
            await this.$store.dispatch('business/deleteInvoiceItem', {
              iid: id,
              iiid: multiChildDiscountItem
            
            })
          }
        }
        // apply discount
        if (amountDue > discountAmount) {
          amountDue -= discountAmount
        } else {
          amountDue = 0
        }
        // add tax
        if (serviceTaxAmount) {
          tax = +((amountDue * serviceTaxAmount).toFixed(2))
          amountDue += tax
        }
        // calculate non-taxable items
        for (let i = 0; i < inv.items.length; i++) {
          let item = inv.items[i]
          if (!item.taxable) {
            console.log('non-taxable item', item)
            amount += item.price
            amountDue += item.price
          }
        }
        // check for free invoice
        if (amountDue < 0.01) {
          transactionId = constants.TID_FREE
        }
        // update invoice
        await this.$store.dispatch('putInvoice', {
          id,
          amount: +(amount.toFixed(2)),
          amountDue: +(amountDue.toFixed(2)),
          tax,
          transactionId
        })

        return inv
      } catch (err) {
        console.error(err)
      }
    },
    dobToAge (dob) {
      return this.$moment().diff(dob, 'years')
    }
  },
  filters: {
    toPercent (val) {
      let percentage = parseFloat(val) * 100
      return percentage.toFixed(0) + '%'
    },
    toDollarAmnt (val) {
      return val.toLocaleString("en-US", {style:"currency", currency:"USD"})
    }
  },
  notifications: {
    showMessage: {},
    showSuccess: {
      title: 'Complete',
      // message: 'Your submission was accepted',
      type: VueNotifications.types.success,
      timeout: 5000
    },
    showError: {
      title: 'Error',
      message: 'Please check your submission and try again',
      type: VueNotifications.types.error,
      timeout: 5000
    }
  }
})
export default {
  name: 'app'
}
</script>

<style lang="scss">
  // CoreUI Icons Set
  @import '~@coreui/icons/css/coreui-icons.min.css';
  /* Import Font Awesome Icons Set */
  $fa-font-path: '~font-awesome/fonts/';
  @import '~font-awesome/scss/font-awesome.scss';
  /* Import Simple Line Icons Set */
  $simple-line-font-path: '~simple-line-icons/fonts/';
  @import '~simple-line-icons/scss/simple-line-icons.scss';
  /* Import Flag Icons Set */
  @import '~flag-icon-css/css/flag-icon.min.css';
  /* Import Bootstrap Vue Styles */
  @import '~bootstrap-vue/dist/bootstrap-vue.css';
  @import '~v-calendar/lib/v-calendar.min.css';
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
