import {HTTP} from '@/services/http-common'
import * as types from '../mutation-types'

const state = {
  marketingSources: {},
  marketingSource: []
}

const getters = {
  marketingSources: state => state.marketingSources,
  marketingSource: state => state.marketingSource
}

const actions = {
  getMarketingSources ({ commit }, params) {
    let id = params && params.id ? '/' + params.id : ''
    return HTTP.get('/marketing_sources' + id, {
      params: params
    }).then(resp => {
      if (id === '') {
        commit(types.SET_MARKETING_SOURCES, resp.data["hydra:member"])
        return resp.data["hydra:member"]
      } else {
        commit(types.SET_MARKETING_SOURCE, resp.data)
        return resp.data
      }
    })
  },
  postMarketingSources ({ commit }, data) {
    return HTTP.post('/marketing_sources', data).then(resp => {
      commit(types.ADD_MARKETING_SOURCE, resp.data)
      return resp.data
    })
  },
  deleteMarketingSources ({ commit }, id) {
    return HTTP.delete('/marketing_sources' + id)
  },
  putMarketingSources ({ commit }, data) {
    return HTTP.put('/marketing_sources/' + data.id, data)
  },
  resetMarketingSources ({ commit }) {
    commit(types.SET_MARKETING_SOURCE, {})
    commit(types.SET_MARKETING_SOURCES, [])
  }
}

const mutations = {
  [types.SET_MARKETING_SOURCE] (state, data) {
    state.marketingSource = data
  },
  [types.SET_MARKETING_SOURCES] (state, data) {
    state.marketingSources = data
  },
  [types.ADD_MARKETING_SOURCE] (state, data) {
    state.marketingSources.push(data)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}