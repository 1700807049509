const TLD = 'com'
export const constants = Object.freeze({
  // URLS
  API_URL: 'https://api.nzonesports.' + TLD + '/api/',
  MEDIA_URL: 'https://api.nzonesports.' + TLD + '/media/',
  SITE_PUBLIC_DOMAIN: 'nzonesports.com',

  IRI_PREFIX: '/api',
  get IRI_USERS () { return this.IRI_PREFIX + '/users/'; },
  get IRI_EVENTS () { return this.IRI_PREFIX + '/events/'; },
  get IRI_EVENT_WELCOMES () { return this.IRI_PREFIX + '/event_welcomes/'; },
  get IRI_PARTICIPANTS () { return this.IRI_PREFIX + '/participants/'; },
  get IRI_EVENT_GROUPS () { return this.IRI_PREFIX + '/event_groups/'; },
  get IRI_SPORTS () { return this.IRI_PREFIX + '/sports/'; },
  // get IRI_ () { return this.IRI_PREFIX + '//'; },

  // SPORTS
  LOOKUP_EXCLUDE: ['Multi-Sport','Other'],

  // CONTENT TYPES
  CONTENT_TYPE_PAGE: 'page',
  CONTENT_TYPE_POST: 'post',
  CONTENT_TYPE_HAPPENING_NOW: 'happening_post',

  // MESSAGE TYPES
  MESSAGE_TYPE_EVENT_GROUP: 'EVENT_GROUP',
  MESSAGE_TYPE_EVENT: 'EVENT',
  MESSAGE_TYPE_MEMBER: 'MEMBER',
  MESSAGE_TYPE_DIRECTOR: 'DIRECTOR',
  
  // USER ROLES
  ROLE_MEMBER: 'ROLE_MEMBER',
  ROLE_COACH: 'ROLE_COACH',

  // INVOICES
  TID_FREE: 'FREE',
  TAX_ITEM_LABEL: 'Tax',

  // MARKETING
  OTHER_SOURCE_ID: "/api/marketing_sources/8",

  LIST_DEFAULT_ITEMS_PER_PAGE: 30
})