import axios from 'axios';
import app from '@/main';
import store from '@/store';
import { constants } from '@/shared/constants';

export const HTTP = axios.create({
  baseURL: process.env.API_URL || constants.API_URL
})

HTTP.CancelToken = HTTP.CancelToken
HTTP.isCancel = HTTP.isCancel

HTTP.interceptors.request.use((config) => {
    app.$Progress.start()
    store.commit('WORKING', true)
    let token = localStorage.getItem('authtoken')

    if (token) {
      config.headers['Authorization'] = `Bearer ${ token }`
    }
    
    config.headers['Accept'] = 'application/ld+json'

    return config
  },

  (error) => {
    app.$Progress.fail()
    store.commit('WORKING', false)
    return Promise.reject(error)
  }
)
//HTTP.interceptors.response.use((response) => { // intercept the global error
//  return response
//  }, function (error) {
//    console.log(error)
//    let originalRequest = error.config
//    if (error.response.status === 401) { 
//      localStorage.removeItem('authtoken')
//    }
//    // Do something with response error
//    return Promise.reject(error)
//})
HTTP.interceptors.response.use(response => {
  app.$Progress.finish()
  store.commit('WORKING', false)
  return response
}, (err) => {
  store.commit('WORKING', false)
  app.$Progress.fail()
  let res = err.response

  if (res.status === 401 && res.config && !res.config.__isRetryRequest) {

    app.$bvToast.toast(res.data.message, {
      title: 'Error',
      autoHideDelay: 5000,
      variant: 'warning',
      appendToast: false
    })

    return new Promise(() => {
      app.$store.dispatch('logout').then(function () {
        app.$router.push('/auth/login')
      })
//      refreshLogin(getRefreshToken(),
//        success => {
//          localStorage.setItem('authtoken', success.refresh_token)//success.access_token
//          err.config.__isRetryRequest = true
//          err.config.headers.Authorization = 'Bearer ' + localStorage.getItem('authtoken')
//          resolve(HTTP(err.config))
//        },
//        error => {
//          console.log('Refresh login error: ', error)
//          reject(error)
//        }
//      )
    })
  } else {
    app.$bvToast.toast(res.data.violations ? res.data.violations[0].message : res.data['hydra:description'], {
      title: 'Error',
      autoHideDelay: 5000,
      variant: 'warning',
      appendToast: false
    })
  }
})
  