import {HTTP} from '@/services/http-common'
import * as types from '../mutation-types'
import { constants } from '@/shared/constants';

const DOT_EXT = '.jsonld'
const MEDIA_SRC = constants.MEDIA_URL

// initial state
const state = {
  supportCategories: [],
  supportMedia: []
}

// getters
const getters = {
  supportMedia: state => {
    return state.supportMedia
  },
  supportCategoryOpts: state => {
    let categories = {
      0:{
        value: null,
        text: 'Select a Category'
      }
    }
    let supportCategories = state.supportCategories
    for (let k in supportCategories) {
      categories[supportCategories[k].id] = {
          value: supportCategories[k].id,
          text: supportCategories[k].labelText
      }
    }
    categories['void'] = {
      value: 'void',
      text: '----',
      disabled: true
    }
    categories['new'] = {
      value: 'new',
      text: 'New Category'
    }
    return categories
  },
  supportFiles: state => {
    let files = {}
    let media = state.supportMedia
    for (let k in media) {
      files[media[k].id] = {
        id: media[k].id,
        media_id: media[k].media.id,
        category_id: media[k].category.id,
        label: media[k].mediaLabel,
        location: MEDIA_SRC + media[k].media.contentUrl,
        description: media[k].mediaDescription
      }
    }
    return files
  }
}

// actions
const actions = {
  
  // categories
  getSupportCategories ({ commit }, params) {
    params = setParams(params)
    return HTTP.get('support_categories' + DOT_EXT, {
      params: params
    }).then(resp => {
      commit(types.SUPPORT_CATEGORIES, resp.data["hydra:member"])
      return resp.data["hydra:member"]
    })
  },
  postSupportCategory ({ commit }, params) {
    return HTTP.post('support_categories' + DOT_EXT, params).then(resp => {
      commit(types.SUPPORT_CATEGORIES, resp.data)
      return resp.data
    }, error => {
      console.log(error)
    })
  },
  putSupportCategory ({ commit }, params) {
    return HTTP.put('support_categories/'+params.id + DOT_EXT, params.data).then(resp => {
      this.dispatch('getSupportCategories')
    }, error => {
      console.log(error)
    })
  },
  deleteSupportCategory ({ commit, state }, id) {
    let media = state.supportMedia
    for (let k in media) {
      if (media[k].category.id === id) {
        throw new Error('You must remove all files from the category first!')
      }
    }
    HTTP.delete('support_categories/' + id + DOT_EXT).then(resp => {
      this.dispatch('getSupportCategories')
    }, error => {
      console.log(error.statusText)
    })
  },
  resetSupportCategories ({ commit }) {
    commit(types.SUPPORT_CATEGORIES, [])
  },

  // media
  getSupportMedia ({ commit }, params) {
    params = setParams(params)
    return HTTP.get('support_media' + DOT_EXT, {
      params: params
    }).then(resp => {
      commit(types.SUPPORT_MEDIA_DATA, resp.data["hydra:member"])
      return resp.data["hydra:member"]
    })
  },
  postSupportMedia ({ commit }, params) {
    return HTTP.post('support_media' + DOT_EXT, params).then(resp => {
      commit(types.SUPPORT_MEDIA_DATA, resp.data)
      return resp.data
    }, error => {
      console.log(error)
    })
  },
  putSupportMedia ({ commit }, params) {
    return HTTP.put('support_media/'+params.id + DOT_EXT, params.data).then(resp => {
      //
    }, error => {
      console.log(error)
    })
  },
  deleteSupportMedia ({ commit, getters }, id) {
    HTTP.delete('support_media/' + id + DOT_EXT).then(resp => {
      this.dispatch('deleteMedia', getters.supportFiles[id].media_id)
      this.dispatch('getSupportMedia')
    }, error => {
      console.log(error)
    })
  },
  resetSupportMedia ({ commit }) {
    commit(types.SUPPORT_MEDIA_DATA, [])
  }
}

// mutations
const mutations = {
  [types.SUPPORT_MEDIA_DATA] (state, data) {
    if (Array.isArray(data)) {
      state.supportMedia = data
    } else {
      state.supportMedia.push(data)
    }
  },
  [types.SUPPORT_CATEGORIES] (state, data) {
    if (Array.isArray(data)) {
      state.supportCategories = data
    } else {
      state.supportCategories.push(data)
    }
  }
}

export default {

  state,
  getters,
  actions,
  mutations
}

function setParams (params) {
  if (!params) {
    params = {}
  }
  return params
}