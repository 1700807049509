import {HTTP} from '@/services/http-common'
import * as types from '../mutation-types'

const DOT_EXT = '.jsonld'

// initial state
const state = {
  pages: [],
  page: {},
  featuredImages: []
}

// getters
const getters = {
    pages: state => state.pages,
    page: state => state.page,
    featuredImages: state => state.featuredImages
}

// actions
const actions = {
  postPage ({ commit }, data) {
    return HTTP.post('contents' + DOT_EXT, data).then(resp => {
      commit(types.PAGE_DATA, resp.data)
      return resp.data
    }, error => {
      console.log(error)
    })
  },
  getPage ({ commit }, params) {
    if (!params) {
      params = {}
    }
    return HTTP.get('contents' + DOT_EXT, {
      params: params
    }).then(resp => {
      commit(types.PAGE_DATA, resp.data["hydra:member"][0])
      return resp.data["hydra:member"][0]
    })
  },
  putPage ({ commit }, obj) {
    return HTTP.put('contents' + '/' + obj.id + DOT_EXT, obj).then(resp => {
      commit(types.PAGE_DATA, resp.data)
      return resp.data
    }, error => {
      console.log(error)
    })
  },
  deletePage ({ state, commit }, id) {
    return HTTP.delete('contents/' + id).then(() => {
      if (state.page.id === id) {
        commit(types.PAGE_DATA, {})
      }
      const k = state.pages.findIndex(e => e.id === id)
      if (k) {
        state.pages.splice(k, 1)
      }
    })
  },
  getPages ({ commit }, params) {
    if (!params) {
      params = {}
    }
    return HTTP.get('contents' + DOT_EXT, {
      params: params
    }).then(resp => {
      commit(types.PAGES_DATA, resp.data["hydra:member"])
      return resp.data["hydra:member"]
    })
  },
  resetPages ({ commit }) {
    commit(types.PAGES_DATA, [])
    commit(types.PAGE_DATA, {})
  },
  resetPage ({ commit }) {
    commit(types.PAGE_DATA, {})
  },
  // FEATURED IMAGES
  getFeaturedImages ({ commit }, params) {
    if (!params) {
      params = {}
    }
    return HTTP.get('image_pools' + DOT_EXT, {
      params: params
    }).then(resp => {
      commit(types.SET_FEATURED_IMAGES, resp.data["hydra:member"])
      return resp.data["hydra:member"]
    })
  },
  resetFeaturedImages ({ commit }) {
    commit(types.SET_FEATURED_IMAGES, [])
  }
}

// mutations
const mutations = {
  [types.PAGES_DATA] (state, data) {
    state.pages = data
  },
  [types.PAGE_DATA] (state, data) {
    state.page = data
  },
  [types.SET_FEATURED_IMAGES] (state, data) {
    state.featuredImages = data
  },
  [types.ADD_FEATURED_IMAGE] (state, data) {
    state.featuredImages.push(data)
  }
}

export default {

  state,
  getters,
  actions,
  mutations
}
