import {HTTP} from '@/services/http-common'
import * as types from '../mutation-types'

const DOT_EXT = '.jsonld'

// initial state
const state = {
  '@id': null,
  id: null,
  roles: [],
  email: null,
  username: null,
  fullName: null,
  address1: null,
  address2: null,
  city: null,
  state: null,
  zipCode: null,
  phone: null,
  isAuthenticated: !!localStorage.getItem("authtoken"),
  originalUser: null
}

// getters
const getters = {
  isAuthenticated: state => state.isAuthenticated,
  currentUser: state => state
}

// actions
const actions = {
  login ({ commit }, { username, password }) {
    commit(types.LOGIN) // show spinner
    return HTTP.post('/login_check', { username, password }).then(result => {
      localStorage.setItem('authtoken', result.data.token)
      return this.dispatch('getCurrentUser', username).then(resp => {
        if (!resp.active) {
          this.dispatch('logout')
          throw "Your account is not active. Please contact corporate to resolve this issue."
        } 
        if (resp.roles.includes('ROLE_DIRECTOR') && typeof resp.businesses[0] !== 'undefined') {
          this.dispatch('getBusiness', {
            id: resp.businesses[0].id
          })
        } else if (resp.roles.includes('ROLE_DIRECTOR') && typeof resp.businesses[0] === 'undefined') {
          this.dispatch('logout')
          throw "Your account is not associated w/ a franchise. Please contact corporate to resolve this issue."
        }
        if ((resp.roles.includes('ROLE_COACH') || resp.roles.includes('ROLE_COACH_JR')) && typeof resp.businesses[0] !== 'undefined') {
          this.dispatch('getBusiness', {
            id: resp.businesses[0].id
          })
        }
        commit(types.LOGIN_SUCCESS)
      })
    }, error => {
      throw error
    })
  },
  async loginAs ({ commit, state }, username) {
    if (!state.roles.includes('ROLE_ADMIN')) return
    let originalUser = state.username
    commit(types.LOGIN)
    return await this.dispatch('getCurrentUser', username).then(resp => {
      commit(types.SET_ORIGINAL_USER, originalUser)
      if (resp.roles.includes('ROLE_DIRECTOR') && typeof resp.businesses[0] !== 'undefined') {
        this.dispatch('getBusiness', {
          id: resp.businesses[0].id
        })
      } else if (resp.roles.includes('ROLE_DIRECTOR') && typeof resp.businesses[0] === 'undefined') {
        this.dispatch('logout')
        throw "Your account is not associated w/ a franchise. Please contact corporate to resolve this issue."
      }
      commit(types.LOGIN_SUCCESS)
      return resp
    })
  },
  revertLoginAs ({ commit, dispatch, state }) {
    return this.dispatch('getCurrentUser', state.originalUser).then(() => {
      commit(types.SET_ORIGINAL_USER, null)
      dispatch('resetBusiness')
      commit(types.LOGIN_SUCCESS)
    })
  },
  logout ({ commit }) {
    commit(types.LOGOUT)
    this.dispatch('resetBusiness')
    this.dispatch('resetBusinesses')
    this.dispatch('resetEvents')
  },
  getCurrentUser ({ commit }, IdUsername) {
    let params = (!isNaN(IdUsername)) ? 'id=' + IdUsername : 'username=' + IdUsername
    return HTTP.get('/users.jsonld?' + params).then(result => {
      let userData = result.data["hydra:member"][0]
      commit(types.CURRENT_USER_DATA, userData)
      return userData
    })
  },
  putCurrentUser ({ commit }, userData) {
    userData.id = state.id
    userData.username = state.username
    let body = {}
    body.data = userData
    return HTTP.put('user.jsonld', userData).then(function (result) {
      commit(types.USER_DATA, result.data["hydra:member"][0])
    })
  },
  // eslint-disable-next-line no-unused-vars
  resetPassword (context, data) {
    return HTTP.post('reset_password', data).then(resp => {
      return resp.data
    })
  }
}

// mutations
const mutations = {
  [types.LOGIN] (state) {
    // this.dispatch('actionPending')
  },
  [types.LOGIN_SUCCESS] (state, data) {
    state.isAuthenticated = true
    // this.dispatch('actionComplete')
  },
  [types.LOGOUT] (state) {
    localStorage.removeItem('authtoken')
    state.isAuthenticated = false
    state.id = null
    state.roles = null
    state.email = null
    state.username = null
    state.fullName = null
    state.originalUser = null
  },
  [types.CURRENT_USER_DATA] (state, data) {
    // user data
    state['@id'] = data['@id']
    state.id = data.id
    state.email = data.email
    state.username = data.username
    state.fullName = data.fullName
    state.roles = data.roles
    state.address1 = data.address1
    state.address2 = data.address2
    state.city = data.city
    state.state = data.state
    state.zipCode = data.zipCode
    state.phone = data.phone
    state.businesses = data.businesses
  },
  [types.SET_ORIGINAL_USER] (state, value) {
    state.originalUser = value
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
