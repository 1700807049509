import Vue from "vue"

Vue.filter('toUSD', function (value) {
  return '$' + Number.parseFloat(value).toFixed(2);
})

Vue.filter('eventStatusName', function (value) {
  return value.split('-')[0]
})

Vue.filter('mysqlDate', function (value) {
  return this.$moment(value).format('YYYY-MM-DD')
})