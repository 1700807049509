import {HTTP} from '@/services/http-common'
import * as types from '../mutation-types'
import StatesUS from '@/shared/_states-us'

const DOT_EXT = '.jsonld'

const API_URI_MEDIA = 'media_objects'
const API_URI_SPORTS = 'sports'
const API_URI_SPORT_PROGRAMS = 'sport_programs'

// initial state
const state = {
  appVersion: process.env.VUE_APP_VERSION,
  isLoading: false,
  sports: [],
  sportProgram: {},
  media: []
}

// getters
const getters = {
  appVersion: state => state.appVersion,
  isLoading: state => state.isLoading,
  states: () => StatesUS,
  sports: state => state.sports,
  sportProgram: state => state.sportProgram,
  media: state => state.media
}

// actions
const actions = {
  setWorking ({ commit }, bool) {
    commit(types.WORKING, bool)
  },
  postMedia ({ commit }, data) {
    let formData  = new FormData()
    for(let name in data) {
      formData.append(name, data[name]);
    }
    return HTTP.post(API_URI_MEDIA, formData).then(resp => {
      commit(types.MEDIA_DATA, [resp.data])
      return resp.data
    }, error => {
      console.log(error)
    })
  },
  deleteMedia ({ commit }, id) {
    return HTTP.delete(API_URI_MEDIA + '/' + id + DOT_EXT).then(() => {
      //
    }, error => {
      console.log(error)
    })
  },
  getSports ({ commit }, params) {
    if (!params) {
      params = {}
    }
    return HTTP.get(API_URI_SPORTS + DOT_EXT, {
      params: params
    }).then(result => {
      commit(types.SPORTS_DATA, result.data["hydra:member"])
    })
  },
  postSport ({ commit }, params) {
    return HTTP.post(API_URI_SPORTS + DOT_EXT, params).then(result => {
      commit(types.SPORT_PROGRAM, result["hydra:member"])
      return result.data
    }, error => {
      console.log(error)
    })
  },
  putSport ({ commit }, obj) {
    return HTTP.put(API_URI_SPORTS + '/' + obj.id + DOT_EXT, obj.data).then(resp => {
      commit(types.SPORT_PROGRAM, resp.data)
      return resp.data
    })
  },
  resetSports ({ commit }) {
    commit(types.SPORTS_DATA, [])
  },
  getSportProgram ({ commit }, params) {
    if (!params) {
      params = {}
    }
    return HTTP.get(API_URI_SPORT_PROGRAMS + DOT_EXT, {
      params: params
    }).then(resp => {
      commit(types.SPORT_PROGRAM, resp.data["hydra:member"][0])
    })
  },
  postSportProgram ({ commit }, params) {
    return HTTP.post(API_URI_SPORT_PROGRAMS + DOT_EXT, params).then(resp => {
      commit(types.SPORT_PROGRAM, resp["hydra:member"])
      return resp
    }, error => {
      console.log(error)
    })
  },
  putSportProgram ({ commit }, obj) {
    return HTTP.put(API_URI_SPORT_PROGRAMS + '/' + obj.id + DOT_EXT, obj.data).then(resp => {
      commit(types.SPORT_PROGRAM, resp.data)
    }, error => {
      console.log(error)
    })
  },
  deleteSportProgram ({ commit }, id) {
    return HTTP.delete(API_URI_SPORT_PROGRAMS + '/' + id + DOT_EXT).then(() => {
      commit(types.SPORT_PROGRAM, {})
    }, error => {
      console.log(error)
    })
  },
  resetSportProgram ({ commit }) {
    commit(types.SPORT_PROGRAM, {})
  }
}

// mutations
const mutations = {
  [types.WORKING] (state, bool) {
    state.isLoading = bool
  },
  [types.SPORTS_DATA] (state, data) {
    state.sports = data
  },
  [types.SPORT_PROGRAM] (state, data) {
    state.sportProgram = data
  },
  [types.MEDIA_DATA] (state, data) {
    state.media.push(data)
  }
}

export default {

  state,
  getters,
  actions,
  mutations
}
