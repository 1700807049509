/* eslint-disable no-empty-pattern */
import {HTTP} from '@/services/http-common'
import * as types from '../mutation-types'

const DOT_EXT = '.jsonld'

// initial state
const state = {
  user: {},
  users: []
}

// getters
const getters = {
  user: state => {
    return state.user // user currently loaded
  },
  userList: state => {
    return state.users // list of users currently loaded
  }
}

// actions
const actions = {
  getUsers ({ commit }, params) {
    this.dispatch('resetUsers')
    return HTTP.get('/users', {
      params: params
    }).then(resp => {
      commit(types.USERS_DATA, resp.data["hydra:member"])
      return resp.data["hydra:member"]
    }, error => {
      console.log('could not retrieve user list', + error)
    })
  },
  getUser ({ commit }, params) {
    let id = (typeof params === "object") ? params.id : params
    return HTTP.get('/users/' + id).then(resp => {
      commit(types.USER_DATA, resp.data)
      return resp.data
    }, error => {
      console.log('could not retrieve user list', error)
    })
  },
  resetUser ({ commit }) {
    commit(types.USER_DATA, {})
  },
  resetUsers ({ commit }) {
    commit(types.USERS_DATA, [])
  },
  postUser ({ commit }, params) {
    return HTTP.post('/users' + DOT_EXT, params).then(resp => {
      commit(types.USERS_DATA, [resp.data])
      return resp.data
    }, error => {
      console.log(error)
    })
  },
  putUser ({}, obj) {
    return HTTP.put('/users/'+obj.uid + DOT_EXT, obj.data).then(resp => {
      return resp.data
    }, error => {
      console.log(error)
    })
  }
}

// mutations
const mutations = {
  [types.USER_DATA] (state, data) {
    state.user = data
  },
  [types.USERS_DATA] (state, data) {
    state.users = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
