import {HTTP} from '@/services/http-common'
import * as types from '../mutation-types'

const DOT_EXT = '.jsonld'
  
// initial state
const state = {
  messages: []
}

// getters
const getters = {
  messages: state => state.messages
}

// actions
const actions = {
  getMessages ({ commit }, params) {
    return HTTP.get('/messages' + DOT_EXT, {
      params: params
    }).then(resp => {
      return resp.data
    })
  },
  postMessage ({ commit }, params) {
    return HTTP.post('/messages' + DOT_EXT, params).then(resp => {
      return resp.data
    })
  },
  getMessage ({ commit }, params) {
    return HTTP.get('/messages' + DOT_EXT, {
      params: params
    }).then(resp => {
      return resp.data["hydra:member"][0]
    })
  },
  deleteMessage ({ commit }, id) {
    return HTTP.delete('/messages/' + id + DOT_EXT).then(() => {}, e => {
      console.log(e)
    })
  },
  putMessage ({ commit }, payload) {
    return HTTP.put('/messages/'+payload.id + DOT_EXT, payload).then(resp => {
      return resp.data
    })
  },
  resetMessages ({ commit }) {
    commit(types.SET_MESSAGES, [])
  }
}

// mutations
const mutations = {
  [types.SET_MESSAGES] (state, payload) {
    state.messages = payload
  },
  [types.ADD_MESSAGE] (state, payload) {
    state.messages.push(payload)
  }
}

export default {

  state,
  getters,
  actions,
  mutations
}
