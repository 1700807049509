import {HTTP} from '@/services/http-common'
import * as types from '../mutation-types'

const DOT_EXT = '.jsonld'

const state = {
  content: []
}

const getters = {
  content: state => state.content
}

const actions = {
  getContent ({ commit }, params) {
    return HTTP.get('/contents' + DOT_EXT, {
      params: params
    }).then(resp => {
      commit(types.SET_CONTENT, resp.data["hydra:member"])
      return resp.data["hydra:member"]
    }, error => {
      console.log(error)
    })
  },
  postContent ({ commit }, params) {
    return HTTP.post('/contents' + DOT_EXT, params).then(resp => {
      commit(types.ADD_CONTENT, resp.data)
      return resp.data
    }, error => {
      console.log(error)
    })
  },
  putContent ({ commit }, params) {
    return HTTP.put('/contents/' + params.id + DOT_EXT, params).then(resp => {
      return resp.data
    }, error => {
      console.log(error)
    })
  },
  deleteContent ({ commit }, id) {
     return HTTP.delete('/contents/' + id).then(() => {}, error => {
      console.log(error)
    })
  },
  resetContent ({ commit }) {
    commit(types.SET_CONTENT, [])
  }
}

const mutations = {
  [types.SET_CONTENT] (state, data) {
    state.content = data
  },
  [types.ADD_CONTENT] (state, data) {
    state.content.push(data)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
